import * as React from "react"
import { Link } from "gatsby"

const translations = {
    en:{
        title:"Not found",
        h1:"Page not found",
        goHome:"Go home"
    },
    fr:{
        title:"Non trouvée",
        h1:"Page non trouvée",
        goHome:"Revenir à l'accueil"
    },
}

function translate(k,lang){
    return translations[lang][k]
}

// markup
const NotFoundPage = ({pageContext}) => {
  const {lang='fr'} = pageContext
  return (
    <div className={"page-not-found"}>
      <title>{translate('title',lang)}</title>
      <h1>{translate('h1',lang)}</h1>
      <p>
        <Link to={`/${lang}`}>{translate('goHome',lang)}</Link>.
      </p>
    </div>
  )
}

export default NotFoundPage
